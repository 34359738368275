// Navbar link contrast increase
.navbar-dark .navbar-nav .nav-link {
    color: #e1e1e1;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #ffffff;
}

.nav-link {
    font-weight: $header-nav-link-font-weight;
}

// Add Abbr color
abbr[title],
abbr[data-original-title] {
    text-decoration-color: $info;
}

.breadcrumb {
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: #e9eeef;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 0.25rem;
}
// Breadcrumb active text contrast increase
.breadcrumb-item.active {
    color: #474d52;
}

// Maintain link color on hover
a:hover {
    color: #205c90;
}

// Spinner for loading
.loading-spinner {
    -webkit-animation-name: loading-spinner;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: loading-spinner;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.intro-text {
    margin-bottom: 0;
    padding-top: 8px;
    padding-bottom: 8px;
}

.fa-info-circle {
    cursor: pointer;
}

.nav-item,
.navbar-text {
    white-space: nowrap;
}

.leaflet-draw-section a {
    &:hover {
        color: white;
        text-decoration: none;
    }
}

.table-max-height {
    max-height: 500px;
}

.systemText {
    font-style: italic;
}

.footer {
    background-color: $footer;
    font-size: 12px;
    color: white;
}

.footer a {
    color: white;
}

.card-header {
    font-weight: bold;
}

.btn-splash {
    background-color: $button-border-and-background;
    border-color: $button-border-and-background;
    color: $button-text-color;
}

.btn-splash:hover {
    color: $button-hover-text-color;
    background: $button-hover-background;
}

.btn-toggle {
    color: #212529;
    background-color: #ababab;
    border-color: #a4a4a4;
}

.btn-toggle:not(:disabled):not(.disabled):active,
.btn-toggle:not(:disabled):not(.disabled).active,
.show > .btn-toggle.dropdown-toggle {
    background-color: $button-border-and-background;
    border-color: $button-border-and-background;
    color: #fff;
}

.btn-toggle:hover {
    cursor: pointer;
    color: #fff;
    background-color: $button-toggle-hover;
    border-color: $button-toggle-hover;
}

.modal-header {
    background-color: $modal-header-background;
    padding: 0.5rem 1rem;
}

.modal-header .close {
    line-height: inherit;
}

.modal-content {
    top: 150px;
}

@import "scss/_variables.scss";

// import Bootstrap AFTER variables
@import "/node_modules/bootstrap/scss/bootstrap";
@import "/node_modules/font-awesome/css/font-awesome.css";

@import "scss/_grids.scss";
@import "/node_modules/leaflet/dist/leaflet.css";
//@import "~leaflet-draw/dist/leaflet.draw.css";

@import "scss/_sticky_footer.scss";
@import "scss/_forms.scss";
@import "scss/_custom-bootstrap.scss";

//ckeditor css

.image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;
}

.image > img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
}

.image-style-side,
.image-style-align-left,
.image-style-align-center,
.image-style-align-right {
    max-width: 50%;
}

.image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

.image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

.image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

.image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
}

figure.media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
    //to account for in popover case
    z-index: 1061;
}
//end ckeditor css

.switch {
    position: relative;
    display: inline-block;
    width: 98px;
    height: 21px;
    padding: 0;
    margin: 0;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6b6b6b;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $button-border-and-background;
}

input:focus + .slider {
    box-shadow: 0 0 1px $button-border-and-background;
}

input:checked + .slider:before {
    -webkit-transform: translateX(77px);
    -ms-transform: translateX(7px);
    transform: translateX(77px);
}

/*------ ADDED CSS ---------*/

.on,
.off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
}

.on {
    display: none;
    color: $button-text-color;
}

input:checked + .slider .on {
    display: block;
}

input:checked + .slider .off {
    display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

//Added leaflet css
.leaflet-control-layers-close {
    color: #0078a8 !important;
    cursor: pointer;
    float: right;
    display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-close {
    display: block;
}

//End of added leaflet css

//NGB Popover

.popover {
    max-width: 400px;
}

.popover-override {
    font-size: 0.75em;
}

.popover-override h3.popover-header {
    background-color: $button-border-and-background;
    color: $button-text-color;
    padding: 0.25em 0.5em;
}

.popover-override h3.popover-header .btn {
    line-height: 1;
}

.popover-override .popover-body {
    padding: 0.25em 0.5em;
}

splash-field-definition-grid-header {
    width: 100%;
}

//End of NGB Popover

//NBG Modal Styles
.allocation-plan-modal .modal-dialog {
    max-width: 590px;
}

button.btnpicker.btnpickerenabled {
    background-color: $button-border-and-background !important;
    &:hover {
        background-color: $button-hover-background !important;
        color: white;
    }
}

button:focus {
    outline: none !important;
}

.btn-xs {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/* Sticky footer styles
// -------------------------------------------------- */

html {
    position: relative;
    height: 100%;
    min-height: 100%;
}
body {
    /* Margin bottom by footer height */
    //margin-bottom: $footer-height + 15px;
    min-height: 100vh;
    height: 100vh;
}
//.footer {
//  position: absolute;
//  bottom: 0;
//  width: 100%;
//  /* Set the fixed height of the footer here */
//  height: $footer-height;
//  line-height: $footer-height; /* Vertically center the text there */
//}

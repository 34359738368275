@use "node_modules/ag-grid-community/styles" as ag;
@import "_variables.scss";

$grid-header-background: #343b85;

@include ag.grid-styles(
    (
        theme: balham,
    )
);

.ag-theme-balham {
    .ag-header {
        background-color: $grid-header-background;
    }

    .ag-header-row {
        color: #fff;
    }

    .ag-header-icon {
        color: #fff;
    }

    .ag-header-cell-menu-button {
        opacity: 1 !important;
    }

    .ag-cell-value.context-menu-container {
        overflow: visible;
    }
}

// .ag-theme-balham {
//   @include ag-theme-balham((
//       // use theme parameters where possible
//       balham-active-color: purple,
//       selected-row-background-color: $button-border-and-background,
//       header-background-color: $grid-header-background,
//       header-foreground-color: #fff,
//       checkbox-checked-color: $grid-header-background,
//       checkbox-unchecked-color: $grid-header-background,
//       checkbox-indeterminate-color: $grid-header-background,
//       range-selection-border-color: $grid-header-background
//   ));

//   .ag-header-icon {
//     color: #FFF;
//   }
// }
